import React from "react"
import { Link } from "gatsby"
import SEO from '../../components/seo'

import Products from '../../layouts/products'



export default () => {



	return (
		<Products>
			<SEO title="終了しました" />

			<section class='black t-center'>
				<h1>この企画は終了しました</h1>
				<p>たくさんのご参加ありがとうございました。</p>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>


			</section>



		</Products>
	)
}