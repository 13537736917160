import React from "react"
import { Link } from "gatsby"

export default () => {

	const nen = new Date().getFullYear()

	return (

		<footer id="p_footer">

			{/* 黒フッター */}
			<div class="kuro_bar" id="copyright">

				{/* 連絡先 */}
				<p>
					<span class="icon mail"></span> i💓elvn.tokyo (💓 → ＠)</p>

				{/* コピーライト */}
				<p>
					<span>© {nen} </span>
					<Link to="/">E.L.V.N</Link>
				</p>

				{/* 謝辞 */}
				<p class="syaji">

					<span class="para"></span>
					{/* クラスparaがないページだとjsにエラーが出るので */}
				</p>

			</div>
		</footer>


	)
}