import React from 'react'
import Helmet from 'react-helmet'
import lazysizes from 'lazysizes'
import './global.css'

import PFooter from '../components/p-footer'

export default ({ children }) => {

	// メルマガページから変遷した時に、背景を白に戻す機能
	if (typeof document !== `undefined`) {
		document.body.classList.remove('haikei-kuro')
	}

	return (
		<>

			<Helmet
				meta={[
					{
						name: `robots`,
						content: `noindex noarchive`,
					}
				]}
			/>



			<main class="b-center products">

				{children}

			</main>

			<PFooter />

		</>
	)
}